import React from "react";
import { Row, Col } from "react-bootstrap";

import { ResetButton, FilterCol } from "~/components/filters";
import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import { useFilters } from "~/components/page-header";
import { cachedAxios, useSearchParams, ensureList } from "~/utils";

async function filtersLoader() {
  const response = await cachedAxios("api/ad-units-filters");
  return response.data;
}

function PageFiltersForm({ options }) {
  const { state, setState } = useSearchParams();
  const requiredFilters = ["adFormat", "app", "platform"];
  useFilters(requiredFilters);

  const initialValues = {
    format: ensureList(state.adFormat) || [],
    app: ensureList(state.app) || [],
    platform: ensureList(state.platform) || [],
  };

  const onChange = (values) => {
    setState({
      adFormat: values.format,
      app: values.app,
      platform: values.platform,
      page: 1,
    });
  };

  const resetSearchState = () => {
    setState({
      adFormat: [],
      app: [],
      platform: [],
      page: 1,
    });
  };

  return (
    <FiltersForm
      options={options}
      initialValues={initialValues}
      onChange={onChange}
      resetSearchState={resetSearchState}
    />
  );
}

function FiltersForm({ options, initialValues, onChange, resetSearchState }) {
  return (
    <Form
      initialValues={initialValues}
      syncSubmit
      onSubmit={onChange}
      enableReinitialize
    >
      <Row className="mb-3 justify-content-md-center">
        <FilterCol label="App" md={3}>
          <Form.Select name="app" options={options.apps} isMulti />
        </FilterCol>
        <FilterCol label="Platform" md={3}>
          <Form.Select name="platform" options={options.platforms} isMulti />
        </FilterCol>
        <FilterCol label="Ad format" md={3}>
          <Form.Select name="format" options={options.formats} isMulti />
        </FilterCol>
      </Row>
      <Row>
        <Col className="d-flex flex-row justify-content-end" md={12} sm={10}>
          <Col xs="auto" className="me-2">
            <ResetButton resetState={resetSearchState} />
          </Col>
          <Col xs="auto">
            <SubmitButton icon={false} title="Apply" />
          </Col>
        </Col>
      </Row>
    </Form>
  );
}

export { filtersLoader, PageFiltersForm, FiltersForm };
