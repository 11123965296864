import {
  faRectangleAd,
  faEnvelopeOpenText,
} from "@fortawesome/free-solid-svg-icons";
import { createBrowserRouter, Navigate } from "react-router-dom";

import Layout from "~/base/layout/external-dashboard";

import {
  AdUnitsPage,
  LogoutPage,
  CountryWaterfallsPage,
  AuthCallbackPage,
  AuthErrorPage,
  SuggestionListPage,
} from "./external-dashboard-pages";
import { ErrorPage } from "./pages";

const config = [
  // these 2 paths don't require user to be authenticated
  // and don't need to inherite base page layout
  {
    path: "logout",
    element: <LogoutPage />,
  },
  {
    path: "auth/sso/callback",
    loader: AuthCallbackPage.loader,
    element: <AuthCallbackPage />,
  },
  {
    path: "auth/error",
    element: <AuthErrorPage />,
  },
  // paths that require authentication / page layout
  {
    path: "/*",
    element: <Layout />,
    loader: Layout.loader,
    errorElement: <ErrorPage />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <Navigate to="ad-units" replace />,
            handle: {
              title: "ARC",
            },
          },
          {
            path: "ad-units",
            handle: {
              crumb: "Ad units",
              title: "ARC: Ad units",
              navbar: {
                title: "Ad Units",
                icon: faRectangleAd,
                color: "#8649e2",
              },
            },
            children: [
              {
                index: true,
                loader: AdUnitsPage.loader,
                element: <AdUnitsPage />,
              },
              {
                path: ":adUnitID/waterfalls",
                loader: CountryWaterfallsPage.loader,
                element: <CountryWaterfallsPage />,
                handle: {
                  getCrumbData: (data) => ({
                    adUnitName: data.countriesData.adUnitName,
                    country: data.country,
                  }),
                  crumb: ({ country, adUnitName }) =>
                    country
                      ? `${adUnitName} - ${country.toUpperCase()} Waterfall`
                      : `${adUnitName} - Country waterfalls`,
                  title: "ARC: Waterfalls",
                },
              },
            ],
          },
          {
            path: "suggestions",
            loader: SuggestionListPage.loader,
            element: <SuggestionListPage />,
            handle: {
              crumb: "Suggestions",
              title: "ARC: Suggestions",
              allowedPermissions: [
                "admin:admin:view",
                "waterfalls_view:all_data:view",
              ],
              navbar: {
                title: "Suggestions",
                icon: faEnvelopeOpenText,
                color: "#2f8a68",
              },
            },
          },
        ],
      },
    ],
  },
];

const router = createBrowserRouter(config);

export default router;
