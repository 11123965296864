import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useRef } from "react";
import { Row, Col, Form, Table } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useLoaderData, useParams, useNavigate } from "react-router-dom";

import Boolean from "~/components/boolean";
import { DetachedSelect as Select } from "~/components/form/select";
import PageHeader from "~/components/page-header";
import Spinner from "~/components/spinner";
import SendSuggestionForm from "~/external-dashboard-blocks/send-suggestion";
import { axios, toOptions, useSearchParams } from "~/utils";

import "./index.scss";

function CountryWaterfallsPage() {
  const { countriesData } = useLoaderData();
  return <WaterfallViewer countriesData={countriesData} />;
}

CountryWaterfallsPage.loader = async function loader({ params, request }) {
  const response = await axios(
    `api/ad-units/${params.adUnitID}/waterfall-countries`
  );
  const url = new URL(request.url);
  const country = url.searchParams.get("country");
  return { countriesData: response.data, country };
};

function useDefaultCountry(countries, country, navigate) {
  function reloadWithCountryParam(countryValue) {
    const url = new URL(window.location.href);
    url.searchParams.set("country", countryValue);
    navigate(url.pathname + url.search, { replace: true });
  }

  function findDefaultCountryChoise(countriesList) {
    const defaultCountry = "us";
    return countriesList.includes(defaultCountry)
      ? defaultCountry
      : countries[0];
  }

  React.useEffect(() => {
    if (countries.length > 0 && !country) {
      reloadWithCountryParam(findDefaultCountryChoise(countries));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, country]);
}

function WaterfallViewer({ countriesData }) {
  const { state, setState } = useSearchParams();
  const { countries, adUnitName } = countriesData;
  const navigate = useNavigate();
  useDefaultCountry(countries, state.country, navigate);

  const [waterfall, setWaterfall] = React.useState(null);
  const { adUnitID } = useParams();
  const setCountry = (country) => {
    setState({ country: country !== null ? country.toLowerCase() : null });
  };
  const { country } = state;

  React.useEffect(() => {
    async function fetchCountryWaterfall() {
      if (country) {
        setWaterfall(null);
        const response = await axios.get(
          `api/ad-units/${adUnitID}/waterfalls/${country}/`
        );
        setWaterfall(response.data);
      }
    }
    fetchCountryWaterfall();
  }, [country, adUnitID]);

  const showSpinner =
    !waterfall && countries.length > 0 && countries.includes(country);

  return (
    <>
      <PageHeader />
      <div className="container mb-0">
        <div className="country-waterfall-forms mt-5">
          <Form className="country-select-form">
            <InputRow label="Name:" name="name">
              <Form.Control value={adUnitName} disabled />
            </InputRow>

            {waterfall && (
              <InputRow label="Last updated:" name="last_updated">
                <Form.Control
                  value={moment(waterfall.updatedAt).format("DD.MM.YYYY")}
                  disabled
                />
              </InputRow>
            )}
            <InputRow label="Country:" name="country">
              <Select
                value={country ? country.toUpperCase() : null}
                onChange={setCountry}
                options={toOptions(countries.map((c) => c.toUpperCase()))}
              />
            </InputRow>
          </Form>
          <div className="suggestion-area">
            {waterfall && (
              <SendSuggestionForm
                country={country}
                adUnitID={adUnitID}
                adUnitName={adUnitName}
              />
            )}
          </div>
        </div>
        {!countries.length && (
          <Alert className="mt-5" key="warning" variant="warning">
            Country waterfalls are not set up for {countriesData.adUnitName}
          </Alert>
        )}
        {showSpinner && <Spinner />}
        {waterfall && (
          <PlacementsTable
            placements={waterfall.placements}
            country={country}
          />
        )}
      </div>
    </>
  );
}

function PlacementsTable({ placements, country }) {
  const tableHeadRef = useRef(null);
  const tableBodyRef = useRef(null);

  React.useEffect(() => {
    const recalculateTableHeight = () => {
      const tableTopRect = tableHeadRef.current.getBoundingClientRect();
      tableBodyRef.current.style.height = `${
        0.95 * (window.innerHeight - tableTopRect.bottom)
      }px`;
    };
    if (tableHeadRef.current && tableBodyRef.current) {
      recalculateTableHeight();
    }
    window.addEventListener("resize", recalculateTableHeight);
    return () => {
      window.removeEventListener("resize", recalculateTableHeight);
    };
  }, []);

  if (placements.length === 0) {
    return (
      <Alert className="mt-5" key="warning" variant="warning">
        There are no placements configured for {country} waterfall.
      </Alert>
    );
  }

  return (
    <div className="country-waterfall">
      <h5 className="mb-2">Waterfall</h5>

      <Table striped bordered hover className="mb-0">
        <thead ref={tableHeadRef}>
          <tr>
            <th scope="col" className="network-name">
              Ad Network
            </th>
            <th scope="col" className="is-bidding">
              Bidding
            </th>
            <th scope="col">Placement ID</th>
            <th scope="col" className="placement-currency-val">
              eCPM <InfoTooltip />
            </th>
            <th scope="col" className="placement-currency-val">
              SOV Rev
            </th>
            <th scope="col" className="placement-currency-val">
              SOV Impr
            </th>
          </tr>
        </thead>
        <tbody ref={tableBodyRef}>
          {placements.map((p) => (
            <tr key={p.id}>
              <td className="network-name">{p.network}</td>
              <td className="is-bidding">
                <Boolean value={p.isBidding} />
              </td>
              <td>{p.placementId}</td>
              <td className="placement-currency-val">{`$${p.ecpm}`}</td>
              <td className="placement-currency-val">{`${rateToPercent(
                p.sovRev
              )}%`}</td>
              <td className="placement-currency-val">{`${rateToPercent(
                p.sovImpr
              )}%`}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

function rateToPercent(rate) {
  return (rate * 100).toFixed(2);
}

function InputRow({ label, name, children }) {
  return (
    <Form.Group as={Row} controlId={name} className="py-2">
      <Form.Label
        column
        sm={2}
        style={{ textAlign: "left", alignSelf: "center" }}
      >
        {label}
      </Form.Label>
      <Col sm={8} className="d-flex flex-wrap align-items-center">
        <div className="w-100">{children}</div>
      </Col>
    </Form.Group>
  );
}

function InfoTooltip() {
  const tooltip = (
    <Tooltip className="info-tooltip">
      <div>
        <p className="my-0">
          The data in the eCPM column represents the following:
        </p>
        <ul className="mb-1">
          <li>
            average eCPMs for the day for bidding networks&lsquo; placements
          </li>
          <li>
            maximum eCPMs for the day for waterfall networks&lsquo; placements
          </li>
        </ul>
      </div>
    </Tooltip>
  );
  return (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement="right"
      overlay={tooltip}
      transition={false}
    >
      <FontAwesomeIcon
        icon={faCircleInfo}
        className="fa-light info-tooltip-icon"
      />
    </OverlayTrigger>
  );
}

export default CountryWaterfallsPage;
