import { useFormikContext } from "formik";
import React from "react";
import { Row, Col } from "react-bootstrap";

import { ResetButton, FilterCol } from "~/components/filters";
import Form from "~/components/form";
import DatePicker, { dateToString } from "~/components/form/date-picker";
import SubmitButton from "~/components/form/submit";
import { useFilters } from "~/components/page-header";
import { useSearchParams, ensureList, cachedAxios } from "~/utils";

async function filtersLoader() {
  const response = await cachedAxios("api/suggestions-filters");
  return response.data;
}

function PageFiltersForm({ options }) {
  const { state, setState } = useSearchParams();
  useFilters([
    "adUnit",
    "country",
    "network",
    "status",
    "dateAfter",
    "dateBefore",
  ]);

  const initialValues = {
    adUnit: ensureList(state.adUnit) || [],
    country: ensureList(state.country) || [],
    network: ensureList(state.network) || [],
    status: ensureList(state.status) || [],
    dateAfter: state.dateAfter ? new Date(state.dateAfter) : null,
    dateBefore: state.dateBefore ? new Date(state.dateBefore) : null,
  };

  const onChange = (values) => {
    setState({
      adUnit: values.adUnit,
      country: values.country,
      network: values.network,
      status: values.status,
      dateAfter: dateToString(values.dateAfter),
      dateBefore: dateToString(values.dateBefore),
      page: 1,
    });
  };

  const resetSearchState = () => {
    setState({
      adUnit: [],
      country: [],
      network: [],
      status: [],
      dateAfter: null,
      dateBefore: null,
      page: 1,
    });
  };
  return (
    <FiltersForm
      options={options}
      initialValues={initialValues}
      onChange={onChange}
      resetSearchState={resetSearchState}
    />
  );
}

function FiltersForm({ options, initialValues, onChange, resetSearchState }) {
  const statusOptions = [
    { value: "reviewed", label: "reviewed" },
    { value: "non_reviewed", label: "non-reviewed" },
  ];
  return (
    <Form
      initialValues={initialValues}
      syncSubmit
      onSubmit={onChange}
      enableReinitialize
    >
      <Row className="mb-3 justify-content-md-center">
        <FilterCol label="Ad Unit" md={3}>
          <Form.Select name="adUnit" options={options.adUnits} isMulti />
        </FilterCol>
        <FilterCol label="Country" md={2}>
          <Form.Select name="country" options={options.countries} isMulti />
        </FilterCol>
        <FilterCol label="Network" md={2}>
          <Form.Select name="network" options={options.networks} isMulti />
        </FilterCol>
        <FilterCol label="Suggestion date" md={3}>
          <DateRange name="date" />
        </FilterCol>
        <FilterCol label="Status" md={2}>
          <Form.Select name="status" options={statusOptions} isMulti />
        </FilterCol>
      </Row>
      <Row>
        <Col className="d-flex flex-row justify-content-end" md={12} sm={10}>
          <Col xs="auto" className="me-2">
            <ResetButton resetState={resetSearchState} />
          </Col>
          <Col xs="auto">
            <SubmitButton icon={false} title="Apply" />
          </Col>
        </Col>
      </Row>
    </Form>
  );
}

function DateRange({ name }) {
  const { values } = useFormikContext();
  const after = `${name}After`;
  const before = `${name}Before`;
  const startDate = values[after];
  const endDate = values[before];
  return (
    <Row>
      <Col xs={6}>
        <DatePicker
          name={after}
          placeholderText="From:"
          autoComplete="off"
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate}
        />
      </Col>
      <Col xs={6}>
        <DatePicker
          name={before}
          placeholderText="To:"
          autoComplete="off"
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
        />
      </Col>
    </Row>
  );
}

export { filtersLoader, PageFiltersForm };
