import moment from "moment-timezone";
import { useState } from "react";
import { Table } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useLoaderData, defer } from "react-router-dom";

import NoFilterResults from "~/components/no-filter-results";
import PageHeader from "~/components/page-header";
import Pagination from "~/components/pagination";
import SimpleAwaiter from "~/components/simple-awaiter";
import TruncatedText from "~/components/truncated-text";
import {
  filtersLoader,
  PageFiltersForm,
} from "~/external-dashboard-blocks/suggestion-list-filters";
import { axios, extractSearch } from "~/utils";
import "./index.scss";

function SuggestionListPage() {
  const { suggestions, filtersPromise } = useLoaderData();
  return (
    <>
      <PageHeader
        filter={
          <SimpleAwaiter promise={filtersPromise}>
            {(filters) => <PageFiltersForm options={filters} />}
          </SimpleAwaiter>
        }
        filterAlwaysOpened
      />
      {suggestions.results.length > 0 ? (
        <SuggestionsTable suggestions={suggestions} />
      ) : (
        <NoFilterResults />
      )}
    </>
  );
}

SuggestionListPage.loader = async function loader({ request }) {
  const search = extractSearch(request.url);
  const suggestionsResponse = await axios(`api/suggestions${search}`);
  const filtersPromise = filtersLoader();
  return defer({
    suggestions: suggestionsResponse.data,
    filtersPromise,
  });
};

function SuggestionsTable({ suggestions }) {
  return (
    <>
      <h5 className="mt-5 mb-2">Suggestions shared by networks:</h5>
      <Table striped bordered hover className="suggestions-table">
        <thead>
          <tr>
            <th scope="col" className="ad-unit">
              Ad Unit
            </th>
            <th scope="col" className="country">
              Country
            </th>
            <th scope="col" className="network">
              Network
            </th>
            <th scope="col" className="suggestion">
              Suggestion
            </th>
            <th scope="col" className="slack-link">
              Slack Link
            </th>
            <th scope="col" className="time">
              Date & Time
            </th>
            <th scope="col" className="username">
              User
            </th>
            <th scope="col" className="reviewed">
              Reviewed
            </th>
          </tr>
        </thead>
        <tbody>
          {suggestions.results.map((suggestion) => (
            <tr key={suggestion.id}>
              <td>{suggestion.adUnitName}</td>
              <td>{suggestion.country.toUpperCase()}</td>
              <td>{suggestion.network}</td>
              <td>
                <TruncatedText content={suggestion.text} />
              </td>
              <td>
                <SlackLink url={suggestion.slackLink} />
              </td>
              <td>
                {moment(suggestion.createdAt)
                  .tz("Europe/London")
                  .format("HH:mm:ss, Do MMM YYYY")}
              </td>
              <td>{suggestion.username}</td>
              <td>
                <SuggestionStatus
                  suggestionId={suggestion.id}
                  initialStatus={suggestion.status}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination data={suggestions} />
    </>
  );
}

function SuggestionStatus({ suggestionId, initialStatus }) {
  const [reviewedStatus, nonReviewedStatus] = ["reviewed", "non_reviewed"];
  const [status, setStatus] = useState(initialStatus);
  const toggleStatus = () => {
    const targetStatus =
      status === reviewedStatus ? nonReviewedStatus : reviewedStatus;
    axios({
      method: "PUT",
      url: `api/suggestions/${suggestionId}/`,
      data: { status: targetStatus },
    }).then(() => {
      setStatus(targetStatus);
    });
  };

  return (
    <Form.Check
      type="switch"
      id={suggestionId}
      checked={status === reviewedStatus}
      onChange={toggleStatus}
    />
  );
}

function SlackLink({ url }) {
  if (url) {
    return (
      <a href={url} target="_blank" rel="noreferrer">
        Link
      </a>
    );
  }
  return "-";
}

export default SuggestionListPage;
