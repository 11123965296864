import {
  faTimes,
  faThumbsUp,
  faThumbsDown,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import * as Yup from "yup";

import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import { supportEmail } from "~/constants";
import { axios } from "~/utils";

import "./index.scss";

function SendSuggestionForm({ country, adUnitID, adUnitName }) {
  const [success, setSuccess] = React.useState(null);
  const formRef = React.useRef(null);

  const handleReset = (isPositive) => {
    setSuccess(null);
    if (isPositive) {
      formRef.current?.resetForm();
    }
  };

  const sendSuggestion = (values) =>
    axios({
      method: "POST",
      url: "api/suggestions/",
      data: {
        country,
        ad_unit: adUnitID,
        text: values.suggestion,
      },
    })
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        setSuccess(false);
      });

  const suggestionValidationSchema = Yup.object().shape({
    suggestion: Yup.string()
      .min(5, "Please make the suggestion at least 5 characters long.")
      .max(
        1000,
        "Suggestion is too long. Please make it up to 1000 characters long."
      )
      .required("Please enter your suggestion"),
  });

  return (
    <>
      <div className="d-flex align-items-center">
        <InfoTooltip />
        <p className="ms-1">Suggestion:</p>
      </div>
      <div className="suggestion-form">
        <Form
          onSubmit={sendSuggestion}
          initialValues={{ suggestion: "" }}
          validationSchema={suggestionValidationSchema}
          innerRef={formRef}
        >
          <Form.Control
            as="textarea"
            name="suggestion"
            placeholder="Create / Pause - eCPM - Placement ID"
            rows={5}
          />
          <Form.Control.ErrorFeedback name="suggestion" />
          <SubmitButton icon={false} title="Submit" />
        </Form>
      </div>
      <FeedbackModal
        success={success}
        adUnitName={adUnitName}
        country={country}
        handleReset={handleReset}
      />
    </>
  );
}

function FeedbackModal({ success, adUnitName, country, handleReset }) {
  const FeedbackComponent = success ? PositiveFeedback : NegativeFeedback;
  const shouldOpen = success !== null;
  return (
    <div className={classNames("modal-wrapper", { open: shouldOpen })}>
      <div className="modal">
        {shouldOpen && (
          <FeedbackComponent
            adUnitName={adUnitName}
            country={country.toUpperCase()}
            handleReset={handleReset}
          />
        )}
      </div>
    </div>
  );
}

function ModalHeader({ adUnitName, country, handleReset, isPositive }) {
  return (
    <div className="head">
      <p className="title">
        {adUnitName} - {country}
      </p>
      <button
        type="button"
        className="close-btn"
        onClick={() => handleReset(isPositive)}
      >
        <FontAwesomeIcon icon={faTimes} aria-hidden />
      </button>
    </div>
  );
}

function PositiveFeedback({ adUnitName, country, handleReset }) {
  return (
    <div className="positive-feedback">
      <ModalHeader
        adUnitName={adUnitName}
        country={country}
        handleReset={handleReset}
        isPositive
      />
      <div className="content">
        <FontAwesomeIcon
          className="reaction-icon"
          icon={faThumbsUp}
          aria-hidden
        />
        <div className="text">
          <p>
            Your suggestion for <b>{adUnitName}</b>, <b>{country}</b>
          </p>
          <p>has been sent. Thank you!</p>
        </div>
      </div>
    </div>
  );
}

function NegativeFeedback({ adUnitName, country, handleReset }) {
  return (
    <div className="negative-feedback">
      <ModalHeader
        adUnitName={adUnitName}
        country={country}
        handleReset={handleReset}
        isPositive={false}
      />
      <div className="content">
        <FontAwesomeIcon
          className="reaction-icon"
          icon={faThumbsDown}
          aria-hidden
        />
        <div className="text">
          <p>Something went wrong, your suggestion was not sent.</p>
          <p>
            Please try again or contact{" "}
            <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
          </p>
        </div>
      </div>
    </div>
  );
}

function InfoTooltip() {
  const tooltip = (
    <Tooltip className="info-tooltip">
      <div>
        <p className="my-0">The recommended format for Suggestions is:</p>
        <b className="my-1">Action - eCPM - Placement ID.</b>
        <p className="my-0">
          You do not need to provide Placement ID if Tripledot Studios team are
          creating the placement.
        </p>
      </div>
    </Tooltip>
  );
  return (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement="left"
      overlay={tooltip}
      transition={false}
    >
      <FontAwesomeIcon
        icon={faCircleInfo}
        className="fa-light info-tooltip-icon"
      />
    </OverlayTrigger>
  );
}

export default SendSuggestionForm;
