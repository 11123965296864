import { useState, useRef, useEffect } from "react";

function TruncatedText({
  content,
  maxRows = 5,
  showMoreText = "Show more",
  showLessText = "Show Less",
}) {
  const [expanded, setExpanded] = useState(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (!contentRef.current) {
      return;
    }
    const contentHeight = contentRef.current.offsetHeight;
    const lineHeight = parseInt(
      window.getComputedStyle(contentRef.current).lineHeight,
      10
    );
    const maxContentHeight = lineHeight * maxRows;
    if (contentHeight > maxContentHeight) {
      setExpanded(false);
    }
  }, [content, maxRows]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  // we use maxRows * 1.2 instead of maxRows * lineHeight to account for any potential
  // extra space between lines
  const contentHeight = expanded === false ? `${maxRows * 1.2}em` : "none";

  return (
    <>
      <div
        ref={contentRef}
        style={{ maxHeight: contentHeight, overflow: "hidden" }}
      >
        {content}
      </div>
      {expanded !== null && (
        <button
          onClick={toggleExpanded}
          type="button"
          style={{
            background: "none",
            border: "none",
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
            padding: 0,
          }}
        >
          {expanded ? showLessText : showMoreText}
        </button>
      )}
    </>
  );
}

export default TruncatedText;
