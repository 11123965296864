import React from "react";
import { Stack } from "react-bootstrap";
import { Link } from "react-router-dom";

import { AuthDispatchContext } from "~/base/auth";
import { axios } from "~/utils";

function LogoutPage() {
  const dispatch = React.useContext(AuthDispatchContext);
  React.useEffect(() => {
    axios({ url: "api/logout", method: "POST" })
      .then(() => {})
      .catch((error) => {
        /* eslint-disable no-console */
        console.log(error);
      });
    dispatch({ type: "logout" });
  });
  return (
    <Stack className="d-flex justify-content-center align-items-center vh-100">
      <h2 className="text-muted">You were logged out</h2>
      <Link to="/">Go to login page</Link>
    </Stack>
  );
}

export default LogoutPage;
