import React from "react";
import { Outlet, useLoaderData } from "react-router-dom";

import { AuthStateContext, redirectToSSO } from "~/base/auth";
import { SearchParamsProvider } from "~/base/search-params";
import TitleChanger from "~/base/title";
import { UserDataProvider } from "~/base/user-data";
import { cachedAxios } from "~/utils";

import Sidebar from "./sidebar";

function Layout() {
  const { loggedIn } = React.useContext(AuthStateContext);
  const userData = useLoaderData();
  if (loggedIn) {
    return (
      <SearchParamsProvider>
        <UserDataProvider value={userData}>
          <div className="d-flex">
            <TitleChanger />
            <Sidebar />
            <main className="container-fluid">
              <Outlet />
            </main>
          </div>
        </UserDataProvider>
      </SearchParamsProvider>
    );
  }
  return redirectToSSO();
}

Layout.loader = async function loader() {
  const response = await cachedAxios("api/user-info");
  return response.data;
};

export default Layout;
