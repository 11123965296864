import {
  faUserLarge,
  faHammer,
  faRightFromBracket,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { AuthStateContext } from "~/base/auth";

import { NavigationSection, NavigationExternalLink } from "../navigation-items";

function UserItemSet({ minimized }) {
  const { user } = React.useContext(AuthStateContext);
  const { username, isSuperuser } = user;

  return (
    <NavigationSection
      prefix="/account"
      icon={<FontAwesomeIcon icon={faUserLarge} />}
      title={username}
      minimized={minimized}
    >
      <NavigationExternalLink
        samePage={false}
        minimized={false}
        to="/guide.pdf"
        title="User Guide"
        icon={<FontAwesomeIcon icon={faBook} />}
      />
      {isSuperuser && (
        <NavigationExternalLink
          samePage
          minimized={false}
          to="/admin/"
          title="Admin"
          icon={<FontAwesomeIcon icon={faHammer} />}
        />
      )}
      <NavigationExternalLink
        samePage
        minimized={false}
        to="/logout"
        title="Sign Out"
        icon={<FontAwesomeIcon icon={faRightFromBracket} />}
      />
    </NavigationSection>
  );
}

export { UserItemSet };
