/* eslint-disable camelcase */
import React from "react";
import { Stack, Button } from "react-bootstrap";

import { redirectToSSO } from "~/base/auth";
import { supportEmail } from "~/constants";

function AuthErrorPage() {
  return (
    <Stack className="d-flex justify-content-center align-items-center vh-100">
      <h1>Something went wrong during login.</h1>
      <h1>Please try again or contact Tripledot Studios Team.</h1>
      <Button
        variant="outline-primary"
        className="mt-4"
        onClick={redirectToSSO}
        size="lg"
      >
        Go to login page
      </Button>
      <h2 className="mt-4 mb-4">OR</h2>
      <h2>
        Email <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
      </h2>
    </Stack>
  );
}

export default AuthErrorPage;
