function NoFilterResults() {
  return (
    <div
      className="centered"
      style={{
        position: "absolute",
        left: "40%",
        top: "50%",
        fontSize: "1.875em",
      }}
    >
      <p className="center">No results found for the filtering parameters.</p>
    </div>
  );
}

export default NoFilterResults;
