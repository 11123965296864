import classnames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

import logoUrl from "./logo.png";

function Brand({ minimized }) {
  return (
    <Link
      to="/"
      className="d-flex align-items-center mx-auto mt-3 text-decoration-none"
    >
      <Logo minimized={minimized} />
    </Link>
  );
}

function Logo({ minimized }) {
  return (
    <>
      <img
        alt="logo"
        height="20"
        className={classnames("me-2", "my-2", { "ms-1": minimized })}
        src={logoUrl}
      />
      {!minimized && <h3 className="fw-bold text-dark mb-0 arc-name">ARC</h3>}
    </>
  );
}

export default Brand;
