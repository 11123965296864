import React from "react";
import { Table, Button } from "react-bootstrap";
import { useLoaderData, defer, Link } from "react-router-dom";

import NoFilterResults from "~/components/no-filter-results";
import PageHeader from "~/components/page-header";
import Pagination from "~/components/pagination";
import SimpleAwaiter from "~/components/simple-awaiter";
import {
  filtersLoader,
  PageFiltersForm,
} from "~/external-dashboard-blocks/ad-units/filters";
import { axios, extractSearch } from "~/utils";

function AdUnitsPage() {
  const { adUnits, filtersPromise } = useLoaderData();

  let table;
  if (adUnits.results.length > 0) {
    table = <AdUnitsTable data={adUnits} />;
  } else {
    table = <NoFilterResults />;
  }

  return (
    <>
      <PageHeader
        filter={
          <SimpleAwaiter promise={filtersPromise}>
            {(filters) => <PageFiltersForm options={filters} />}
          </SimpleAwaiter>
        }
        filterAlwaysOpened
      />
      {table}
    </>
  );
}

AdUnitsPage.loader = async function loader({ request }) {
  const search = extractSearch(request.url);
  const response = await axios(`api/ad-units${search}`);
  const filtersPromise = filtersLoader();

  return defer({
    adUnits: response.data,
    filtersPromise,
  });
};

function AdUnitsTable({ data }) {
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">App</th>
            <th scope="col">Platform</th>
            <th scope="col">Ad format</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.results.map((adUnit) => (
            <tr key={adUnit.id}>
              <td>{adUnit.name}</td>
              <td>{adUnit.app}</td>
              <td>{adUnit.platform}</td>
              <td>{adUnit.adFormat}</td>
              <td>
                <Link to={`/ad-units/${adUnit.id}/waterfalls`}>
                  <Button variant="outline-primary" style={{ width: "90%" }}>
                    View
                  </Button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination data={data} />
    </>
  );
}

export default AdUnitsPage;
