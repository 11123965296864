/* eslint-disable camelcase */
import jwt_decode from "jwt-decode";
import React from "react";
import { useLoaderData, useNavigate } from "react-router-dom";

import {
  AuthDispatchContext,
  checkOAuthSessionState,
  removeOAuthSessionState,
} from "~/base/auth";
import { axios } from "~/utils";

function AuthCallbackPage() {
  const { token } = useLoaderData();
  const navigate = useNavigate();
  const dispatch = React.useContext(AuthDispatchContext);

  React.useEffect(() => {
    if (token) {
      const { email, username, isSuperuser } = jwt_decode(token);
      dispatch({
        type: "login",
        data: { token, email, username, isSuperuser },
      });
      // we use a small timeout here so make sure dispatch action is complete
      // and localstorage is updated with auth details
      setTimeout(() => navigate("/ad-units"), 500);
    } else {
      navigate("/auth/error");
    }
  });

  // Render empty page while handling OAuth2 flow
  return "";
}

AuthCallbackPage.loader = async function loader({ request }) {
  const url = new URL(request.url);
  const code = url.searchParams.get("code");
  const state = url.searchParams.get("state");

  let token = null;
  if (!checkOAuthSessionState(state)) {
    return { token };
  }
  if (code) {
    try {
      const response = await axios({
        method: "POST",
        url: "api/sso/login",
        data: {
          code,
          state,
        },
      });
      token = response.data.token;
    } catch (
      e
      // eslint-disable-next-line no-empty
    ) {
    } finally {
      removeOAuthSessionState();
    }
  }
  return { token };
};

export default AuthCallbackPage;
